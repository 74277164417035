import React, { useCallback, useMemo, useState, useEffect } from "react";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddEditDomain from "../components/popup";
import Spinner from "../components/circularProgress";
import DomainService from "../services/domain.service";
import { Chip, IconButton, Tooltip } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TerminalService from "../services/terminal.service";
import ProxyService from "../services/proxy.service";
import Table from "../components/Table";
import DomainRow from "../components/manageDomains/row";
import AutoComplete from "../components/autocomplete";
import Switch from "../components/switch";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder } from "../utils/appConstants";
import clsx from "clsx";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

const ManageDomains = ({ user, actAsDomainIdMemoised, loggedInUser }) => {
  //eslint-disable-next-line
  const domainNameRegex = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/;
  const NumRegex = /^[0-9]+$/

  const [addEditDomainLoading, setAddEditDomainLoading] = useState(false);
  const [selectedTerminals, setSelectedTerminals] = useState([]);
  const [selectedActiveTerminals, setSelectedActiveTerminals] = useState([]);
  const [selectedProxies, setSelectedProxies] = useState({});
  const [terminalsInputValue, setTerminalsInputValue] = useState("");
  const [activeTerminalsInputValue, setActiveTerminalsInputValue] = useState("");
  const [proxyInputValue, setProxyInputValue] = useState("");
  const [deleteDomainLoading, setDeleteDomainLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editDomainId, setEditDomainId] = useState("");
  const [deleteDomainFlag, setDeleteDomainFlag] = useState(false);
  const [deleteDomainId, setDeleteDomainId] = useState("");
  const [open, setOpen] = useState(false);
  const [domainAdded, setDomainAdded] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [isProxyDomain, setIsProxyDomain] = useState(false);
  const [carrierAlias, setCarrierAlias] = useState("");
  const [tempEmailInputValue, setTempEmailInputValue] = useState("");
  const [authType, setAuthType] = useState('apiKey');
  const [domainTypes, setDomainTypes] = useState({
    carrier: false,
    terminal: false
  });

  const [carrierFields, setCarrierFields] = useState({
    orderProcessingCallbackURI: "",
    authURI: "",
    username: "",
    password: "",
    sendEmailsForXRef: false,
    isFTPChecked: false,
    publicKey: "",
    emailsForXRef: []
  });

  const [terminalFields, setTerminalFields] = useState({
    orderProcessingURI: "",
    orderCancellingURI: "",
    setProductQuantitiesAsZero: false,
    shortenTrailerId: false,
    enableValidation: false,
    timeAdjust: {
      effectiveDate: 7,
      expirationDate: 48
    },
    enableAllocationCheck: true,
    hostUsername: "",
    hostPassword: "",
    apiKey: "",
    authURI: "",
    username: "",
    password: "",
    token: "",
    includeUTCDate: false,
    params: [{ key: '', value: '' }],
  });
  const [isAPIKey, setIsAPIKey] = useState(true);
  const [isPublicHost, setIsPublicHost] = useState(false);

  const openModal = () => {
    setOpen(true);
    setDomainName('');
  }

  const closeModal = () => {
    setOpen(false);
    setDomainName("")
    setIsProxyDomain(false)
    setDomainTypes({
      carrier: false,
      terminal: false
    })
    setCarrierFields({
      orderProcessingCallbackURI: "",
      authURI: "",
      username: "",
      password: "",
      sendEmailsForXRef: false,
      isFTPChecked: false,
      publicKey: "",
      emailsForXRef: []
    })
    setTerminalFields({
      orderProcessingURI: "",
      orderCancellingURI: "",
      setProductQuantitiesAsZero: false,
      shortenTrailerId: false,
      enableValidation: false,
      timeAdjust: {
        effectiveDate: 7,
        expirationDate: 48
      },
      enableAllocationCheck: true,
      hostUsername: "",
      hostPassword: "",
      apiKey: "",
      authURI: "",
      username: "",
      password: "",
      token: "",
      includeUTCDate: false,
      params: [{ key: '', value: '' }],
    })
    setEdit(false)
    setDeleteDomainFlag(false)
    setDeleteDomainId("")
    setEditDomainId("")
    setSelectedTerminals([])
    setSelectedProxies({})
    setTerminalsInputValue("")
    setCarrierAlias("")
    setTempEmailInputValue('')
  }

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.asc, defaultOrderBy: 'created_date' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: domains, total: total, loading: domainsLoading } = useFetch({
    service: DomainService,
    func: 'getDomains',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage, isList: true,
      id: actAsDomainIdMemoised || (user.attributes['custom:role'] !== "superadmin" ? loggedInUser.domain_id : "")
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, domainAdded, order, orderBy, page, perPage, loggedInUser.domain_id, user.attributes['custom:role'], actAsDomainIdMemoised],
    onSuccess: () => setDomainAdded(false),
    skip: user.attributes['custom:role'] !== "superadmin" && !loggedInUser.domain_id
  });

  const { data: terminalOptions } = useFetch({
    service: TerminalService,
    func: 'getTerminals',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      searchText: terminalsInputValue,
      domainCheck: true
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, terminalsInputValue],
  });

  const { data: activeTerminalOptions } = useFetch({
    service: TerminalService,
    func: 'getActiveTerminals',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      searchText: terminalsInputValue
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, terminalsInputValue],
  });

  const { data: proxyOptions } = useFetch({
    service: ProxyService,
    func: 'getProxies',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      searchText: proxyInputValue,
      order: "ASC",
      orderBy: "created_date",
      page: 1,
      perPage: 1000
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, proxyInputValue],
  });

  const addEditDomain = useCallback(() => {
    setAddEditDomainLoading(true);

    let payload = {
      domain_name: domainName.trim(),
      isProxyDomain: isProxyDomain
    }

    if (domainTypes.carrier && !isProxyDomain) {
      payload["carrier_alias"] = carrierAlias;
      payload["carrier"] = {
        authURI: carrierFields.authURI.trim() || "",
        username: carrierFields.username.trim() || "",
        password: carrierFields.password.trim() || "",
        orderProcessingCallbackURI: carrierFields.orderProcessingCallbackURI.trim() || "",
        emails: carrierFields.emailsForXRef,
        isFTPChecked: carrierFields.isFTPChecked,
        publicKey: carrierFields.publicKey.trim() || "",
        terminals: selectedActiveTerminals && selectedActiveTerminals.length ? selectedActiveTerminals.map(item => item.value) : [],
        carrierProxy: selectedProxies.value
      }
    }

    if (domainTypes.terminal && !isProxyDomain) {
      payload["terminal"] = {
        apiKey: isAPIKey && authType === 'apiKey' && terminalFields.apiKey.trim() || "",
        authURI: !isAPIKey && terminalFields.authURI.trim() || "",
        username: !isAPIKey && terminalFields.username.trim() || "",
        password: !isAPIKey && terminalFields.password.trim() || "",
        setProductQuantitiesAsZero: terminalFields.setProductQuantitiesAsZero,
        tpHostUsername: terminalFields.hostUsername ? terminalFields.hostUsername.trim() : "",
        tpHostPassword: terminalFields.hostPassword ? terminalFields.hostPassword.trim() : "",
        enableTPChecks: terminalFields.enableAllocationCheck,
        orderSendingTo: isPublicHost ? 'public_host' : 'private_host',
        shorten_trailer_id: terminalFields.shortenTrailerId,
        enableValidation: terminalFields.enableValidation,
        timeAdjust: {
          effectiveDate: terminalFields.timeAdjust.effectiveDate,
          expirationDate: terminalFields.timeAdjust.expirationDate
        },
        orderProcessingURI: terminalFields.orderProcessingURI.trim() || "",
        orderCancellingURI: terminalFields.orderCancellingURI.trim() || "",
        token: isAPIKey && authType === 'token' && terminalFields.token.trim() || "",
        params: isAPIKey && authType === 'token' && terminalFields.params || "",
        includeUTCDate: isAPIKey && authType === 'token' && terminalFields.includeUTCDate || false,
      }

      if (selectedTerminals.length > 0) {
        payload.terminalIds = selectedTerminals.map(item => item.value)
      }
    }

    if (edit) {
      payload.domainId = editDomainId;
      delete payload.domain_name;
      DomainService.editDomain(user?.signInUserSession?.accessToken?.jwtToken, payload)
        .then(() => {
          setAddEditDomainLoading(false)
          setDomainAdded(true)
          closeModal()
        }).catch((err) => {
          toast.error(err.message || "Something went wrong", { icon: false });
          setAddEditDomainLoading(false)
        })
    } else {
      DomainService.addDomain(user?.signInUserSession?.accessToken?.jwtToken, payload)
        .then(() => {
          setAddEditDomainLoading(false)
          setDomainAdded(true)
          closeModal()
        }).catch((err) => {
          toast.error(err.message || "Something went wrong", { icon: false });
          setAddEditDomainLoading(false)
        })
    }
  }, [
    domainName, isProxyDomain, edit, editDomainId, carrierAlias, JSON.stringify(selectedTerminals), JSON.stringify(selectedActiveTerminals),
    domainTypes.carrier, domainTypes.terminal,
    isAPIKey, isPublicHost,
    JSON.stringify(selectedProxies),
    terminalFields.orderProcessingURI, terminalFields.orderCancellingURI, terminalFields.apiKey, terminalFields.authURI, terminalFields.username, terminalFields.password, terminalFields.setProductQuantitiesAsZero, terminalFields.shortenTrailerId,terminalFields.enableValidation, terminalFields.timeAdjust.effectiveDate, terminalFields.timeAdjust.expirationDate, terminalFields.enableAllocationCheck, terminalFields.hostUsername, terminalFields.hostPassword,
    carrierFields.orderProcessingCallbackURI, carrierFields.authURI, carrierFields.username, carrierFields.password, carrierFields.sendEmailsForXRef, carrierFields.isFTPChecked, carrierFields.publicKey, JSON.stringify(carrierFields.emailsForXRef),
    user?.signInUserSession?.accessToken?.jwtToken,authType,
    terminalFields.token, terminalFields.includeUTCDate, JSON.stringify(terminalFields.params)
  ])

  const deleteDomain = useCallback(() => {
    setDeleteDomainLoading(true);

    DomainService.deleteDomain(user?.signInUserSession?.accessToken?.jwtToken, deleteDomainId)
      .then(() => {
        setDeleteDomainLoading(false)
        setDomainAdded(true)
        closeModal()
      })
  }, [
    user?.signInUserSession?.accessToken?.jwtToken,
    deleteDomainId
  ])

  const handleInputChange = (e) => {
    setDomainName(e.target.value);
  }

  const handleIsProxyDomainChange = (e) => {
    setIsProxyDomain(e.target.value);
  }

  const handleCarrierAliasChange = (e) => {
    setCarrierAlias(e.target.value);
  }

  const handleTerminalsInputChange = (val) => {
    setTerminalsInputValue(val);
  }

  const handleActiveTerminalsInputChange = (val) => {
    setTerminalsInputValue(val);
  }

  const handleProxyInputChange = (val) => {
    setProxyInputValue(val);
  }

  const handleCheckboxChange = (clickBlock) => {
    if (clickBlock === 'carrier') {
      setDomainTypes(item => {
        return {
          ...item,
          carrier: !item.carrier
        }
      })
    } else {
      setDomainTypes(item => {
        return {
          ...item,
          terminal: !item.terminal
        }
      })
    }
  }

  const validateRegex = useMemo(() => {
    return domainName.trim() && !domainNameRegex.test(domainName.trim())
  }, [domainName]);

  const handleCarrierFieldsChange = (e, name) => {
    setCarrierFields(item => {
      return {
        ...item,
        [name]: e.target.value
      }
    })
  }

  const handleTerminalFieldsChange = (e, name) => {
    setTerminalFields(item => {
      return {
        ...item,
        [name]: e.target.value
      }
    })
  }

  const addParam = () => {
    setTerminalFields({
      ...terminalFields,
      params: [...terminalFields.params, { key: '', value: '' }],
    });
  };

  const removeParam = (index) => {
    const updatedParams = terminalFields.params.filter((_, i) => i !== index);
    setTerminalFields({ ...terminalFields, params: updatedParams });
  };

  const handleParamChange = (index, field, value) => {
    const updatedParams = terminalFields.params.map((param, i) =>
      i === index ? { ...param, [field]: value } : param
    );
    setTerminalFields({ ...terminalFields, params: updatedParams });
  }

  const handleEdit = useCallback((domainData) => {
    const tempDomainTypes = {
      carrier: false,
      terminal: false
    }

    if (domainData.carrier) {
      tempDomainTypes.carrier = true
    }

    if (domainData.terminal && Object.keys(domainData.terminal).filter(item => domainData.terminal[item] === "").length !== Object.keys(domainData.terminal).length) {
      tempDomainTypes.terminal = true
    }
    setEdit(true)
    setOpen(true)
    setDomainName(domainData.domain_name)
    setIsProxyDomain(domainData.isProxyDomain)
    if(domainData.isProxyDomain){
      setDomainTypes({
        carrier: false,
        terminal: false
      })
    }else{
      setDomainTypes(tempDomainTypes)
    }
    setCarrierFields((fields) => {
      return {
        ...fields,
        ...domainData.carrier,
        sendEmailsForXRef: domainData?.carrier?.emails && domainData?.carrier?.emails?.length ? true : false,
        emailsForXRef: domainData?.carrier?.emails || []
      }
    })
    setTerminalFields((fields) => {
      return {
        ...fields,
        ...domainData.terminal,
        shortenTrailerId: domainData?.terminal?.shorten_trailer_id ?? false,
        enableValidation: domainData?.terminal?.enableValidation ?? false,
        hostUsername: domainData?.terminal?.tpHostUsername,
        hostPassword: domainData?.terminal?.tpHostPassword,
        enableAllocationCheck: domainData?.terminal?.enableTPChecks ?? false,
      }
    })
    setIsPublicHost(domainData?.terminal?.orderSendingTo === 'public_host' ? true : false)
    setEditDomainId(domainData._id)

    if (domainData.terminal && domainData.terminal.apiKey) {
      setIsAPIKey(true)
      setAuthType('apiKey')
    } else if(domainData.terminal && domainData.terminal.token) {
      setIsAPIKey(true)
      setAuthType('token')
    }else {
      setIsAPIKey(false)
      setAuthType('apiKey')
    }

    const tempTerminalOptions = (domainData?.terminals || []).map(item => {
      return { value: item._id, label: `${item.control_number} - ${item.name}` }
    })

    const tempActiveTerminalOptions = (domainData?.carrier?.terminals || []).map(item => {
      const activeTerminal = activeTerminalOptions.find(activeTerminalItem => activeTerminalItem._id === item)
      return { value: item, label: `${activeTerminal?.control_number} - ${activeTerminal?.name}` }
    })

    let tempSelectedProxy = proxyOptions.find(item => item._id === domainData?.carrier?.carrierProxy)
    if (tempSelectedProxy) {
      setSelectedProxies({ value: tempSelectedProxy?._id, label: tempSelectedProxy?.name });
    } else {
      setSelectedProxies({})
    }

    setSelectedTerminals(tempTerminalOptions);
    setSelectedActiveTerminals(tempActiveTerminalOptions);

    setCarrierAlias(domainData?.carrier_alias)
  }, [JSON.stringify(activeTerminalOptions), JSON.stringify(proxyOptions)])

  const handleDelete = (domainData) => {
    setOpen(true)
    setDeleteDomainFlag(true)
    setDeleteDomainId(domainData._id)
  }

  const handleTerminalSelectionChange = useCallback((terminals) => {
    setSelectedTerminals(terminals)
    handleTerminalsInputChange(terminalsInputValue)
  }, [terminalsInputValue])

  const handleActiveTerminalSelectionChange = useCallback((terminals) => {
    setSelectedActiveTerminals(terminals)
    handleActiveTerminalsInputChange(activeTerminalsInputValue)
  }, [activeTerminalsInputValue])

  const handleProxySelectionChange = useCallback((proxies) => {
    setSelectedProxies(proxies)
    handleProxyInputChange(proxyInputValue)
  }, [proxyInputValue])

  const handleTempEmailInputValueChange = useCallback((e) => {
    setTempEmailInputValue(e.target.value)
  }, [])

  const handleTimeAdjustChange = (e, key) => {
    if (e.target.value.trim() === '') {
      if (key === 'effectiveDate') {
        setTerminalFields((fields) => {
          return {
            ...fields,
            timeAdjust: {
              ...fields.timeAdjust,
              effectiveDate: ''
            }
          }
        })
      } else if (key === 'expirationDate') {
        setTerminalFields((fields) => {
          return {
            ...fields,
            timeAdjust: {
              ...fields.timeAdjust,
              expirationDate: ''
            }
          }
        })
      }
    } else if (NumRegex.test(e.target.value.trim())) {
      if (key === 'effectiveDate') {
        setTerminalFields((fields) => {
          return {
            ...fields,
            timeAdjust: {
              ...fields.timeAdjust,
              effectiveDate: parseInt(e.target.value.trim())
            }
          }
        })
      } else if (key === 'expirationDate') {
        setTerminalFields((fields) => {
          return {
            ...fields,
            timeAdjust: {
              ...fields.timeAdjust,
              expirationDate: parseInt(e.target.value.trim())
            }
          }
        })
      }
    }
  }

  const handleEmailsForXRefEnter = useCallback((e) => {
    if (e.keyCode === 13) {
      setCarrierFields(item => {
        return {
          ...item,
          emailsForXRef: [...item.emailsForXRef, e.target.value]
        }
      })
      setTempEmailInputValue('')
    }

  }, [JSON.stringify(carrierFields.emailsForXRef)])

  const handleDeleteEmailForXRef = useCallback((emailForXRef) => {
    setCarrierFields(item => {
      return {
        ...item,
        emailsForXRef: item.emailsForXRef.filter(emailForXRefItem => emailForXRefItem !== emailForXRef)
      }
    })
  }, [JSON.stringify(carrierFields.emailsForXRef)])

  const loadOptions = (inputValue) => {
    return new Promise((resolve, rej) => {
      TerminalService.getTerminals({ token: user?.signInUserSession?.accessToken?.jwtToken, searchText: inputValue, domainCheck: true })
        .then(res => {
          const tempTerminalOptions = (res.data.data || []).map(item => {
            return { value: item._id, label: `${item.control_number} - ${item.name}` }
          })
          resolve(tempTerminalOptions)
        })
        .catch(err => {
          resolve([])
        })
    })
  }

  const loadActiveTerminalOptions = (inputValue) => {
    return new Promise((resolve, rej) => {
      TerminalService.getActiveTerminals({ token: user?.signInUserSession?.accessToken?.jwtToken, searchText: inputValue })
        .then(res => {
          const tempTerminalOptions = (res.data.data || []).map(item => {
            return { value: item._id, label: `${item.control_number} - ${item.name}` }
          })
          resolve(tempTerminalOptions)
        })
        .catch(err => {
          resolve([])
        })
    })
  }

  const loadProxyOptions = (inputValue) => {
    return new Promise((resolve, rej) => {
      ProxyService.getProxies({
        token: user?.signInUserSession?.accessToken?.jwtToken,
        searchText: inputValue,
        order: "ASC",
        orderBy: "created_date",
        page: 1,
        perPage: 1000
      })
        .then(res => {
          const tempProxyOptions = (res.data.data || []).map(item => {
            return { value: item._id, label: `${item.name}` }
          })
          resolve(tempProxyOptions)
        })
        .catch(err => {
          resolve([])
        })
    })
  }

  const addEditDomainTitle = useMemo(() => {
    return edit ? "Edit Domain" : "Add Domain"
  }, [edit]);

  const onSwitchChange = (checked) => {
    setIsAPIKey(checked)
  }

  const handleAuthTypeChange = (e) => {
    setAuthType(e.target.value);
  };
  const onHostSwitchChange = (checked) => {
    setIsPublicHost(checked)
  }

  const addEditModalContent = useMemo(() => {
    return (
      <div className="add-domain">
        <div className="form-field-box">
          <label>Domain Name <span className="required">*</span> {!edit && <span className="note">Domain name is non editable once domain is created</span>}</label>
          <input value={domainName} onChange={handleInputChange} disabled={edit} readOnly={edit} className={clsx(edit && 'disabled')} placeholder="Enter Domain Name" />
          <label className="d-error">{domainName.trim() && validateRegex && 'Please Enter Valid Domain Name'}</label>
        </div>
        <div className="details-block-selection">
          <input type="checkbox" value={isProxyDomain} checked={isProxyDomain} onChange={(e) => handleIsProxyDomainChange({ target: { value: e.target.checked } })} />
          <label>Is Proxy Domain?</label>
        </div>
        <div className="domain-details">
          <div className={clsx("details-block carrier-details", isProxyDomain && 'proxy-domain-selected')}>
            <div className="details-block-selection">
              <input type="checkbox" value={domainTypes.carrier} checked={domainTypes.carrier} onChange={() => handleCheckboxChange('carrier')} />
              <label>Carrier</label>
            </div>
            <div className={clsx("domain-type-form", domainTypes.carrier && 'selected')}>
              <div className="form-field-box">
                <label>Alias</label>
                <input value={carrierAlias} placeholder="Enter Alias" onChange={(e) => handleCarrierAliasChange(e)} />
              </div>
              {/* <div className="form-field-box">
                <label>Proxy</label>
                <AutoComplete
                  loadOptions={loadProxyOptions}
                  isMulti="false"
                  defaultOptions={proxyOptions.map(item => {
                    return { value: item._id, label: `${item.name}` }
                  })}
                  onChange={handleProxySelectionChange}
                  value={selectedProxies}
                  onInputChange={handleProxyInputChange}
                  inputValue={proxyInputValue}
                />
              </div> */}
              <div className="form-field-box">
                <label>Order Processing Callback URI</label>
                <input value={carrierFields.orderProcessingCallbackURI} placeholder="Enter Callback URI" onChange={(e) => handleCarrierFieldsChange(e, "orderProcessingCallbackURI")} />
              </div>

              <div className="form-field-box">
                <label>Auth URI</label>
                <input value={carrierFields.authURI} placeholder="Enter Auth URI" onChange={(e) => handleCarrierFieldsChange(e, "authURI")} />
              </div>
              <div className="form-field-box">
                <label>Username</label>
                <input value={carrierFields.username} placeholder="Enter Username" autoComplete="new-password" onChange={(e) => handleCarrierFieldsChange(e, "username")} />
              </div>
              <div className="form-field-box">
                <label>Password</label>
                <input value={carrierFields.password} type="password" placeholder="Enter Password" autoComplete="new-password" onChange={(e) => handleCarrierFieldsChange(e, "password")} />
              </div>

              <div className="details-block-selection">
                <input type="checkbox" value={carrierFields.sendEmailsForXRef} checked={carrierFields.sendEmailsForXRef} onChange={(e) => handleCarrierFieldsChange({ target: { value: e.target.checked } }, 'sendEmailsForXRef')} />
                <label>Send Email for X-REF Errors</label>
              </div>
              {
                carrierFields.sendEmailsForXRef && <div className="form-field-box">
                  <div>
                    {
                      (carrierFields.emailsForXRef || []).map((emailForXRef, index) => {
                        return <Chip key={index} label={emailForXRef} variant="outlined" onDelete={() => handleDeleteEmailForXRef(emailForXRef)} />
                      })
                    }
                    <input
                      placeholder="Enter Email And Press Enter Key"
                      className="multi-emails-input"
                      value={tempEmailInputValue}
                      onChange={handleTempEmailInputValueChange}
                      onKeyDown={handleEmailsForXRefEnter}
                    />
                  </div>
                </div>
              }

              <div className="details-block-selection">
                <input type="checkbox" value={carrierFields.isFTPChecked} checked={carrierFields.isFTPChecked} onChange={(e) => handleCarrierFieldsChange({ target: { value: e.target.checked } }, 'isFTPChecked')} />
                <label>FTP</label>
              </div>

              {
                carrierFields.isFTPChecked &&
                <>
                  <div className="form-field-box">
                    <label>Public Key</label>
                    <input value={carrierFields.publicKey} placeholder="Enter Public Key" onChange={(e) => handleCarrierFieldsChange(e, "publicKey")} />
                  </div>
                  <div className="form-field-box">
                    <label>Active Terminals</label>
                    <AutoComplete
                      loadOptions={loadActiveTerminalOptions}
                      defaultOptions={activeTerminalOptions.map(item => {
                        return { value: item._id, label: `${item.control_number} - ${item.name}` }
                      })}
                      onChange={handleActiveTerminalSelectionChange}
                      value={selectedActiveTerminals}
                      onInputChange={handleActiveTerminalsInputChange}
                      inputValue={activeTerminalsInputValue}
                    />
                    {/* {domainTypes.terminal && (!selectedTerminals || !selectedTerminals.length) && <label className="d-error">Atleast one terminal required for terminal domain</label>} */}
                  </div>
                </>
              }
            </div>
          </div>
          <div className="break-line"></div>
          <div className={clsx("details-block terminal-details", isProxyDomain && 'proxy-domain-selected')}>
            <div className="details-block-selection">
              <input type="checkbox" value={domainTypes.terminal} checked={domainTypes.terminal} onChange={() => handleCheckboxChange('terminal')} />
              <label>Terminal</label>
            </div>
            <div className={clsx("domain-type-form", domainTypes.terminal && 'selected')}>
              <div className="form-field-box details-block-selection">
                <input type="checkbox" value={terminalFields.enableAllocationCheck} checked={terminalFields.enableAllocationCheck} onChange={(e) => handleTerminalFieldsChange({ target: { value: e.target.checked } }, 'enableAllocationCheck')} />
                <label>Enable allocation check</label>
              </div>
              <div className="form-field-box">
                <label>Public (Allocation check) Host Username {isPublicHost ? <span className="required">*</span> : ""}</label>
                <input value={terminalFields.hostUsername} placeholder="Enter Host Username" autoComplete="new-password" onChange={(e) => handleTerminalFieldsChange(e, 'hostUsername')} />
              </div>
              <div className="form-field-box">
                <label>Public (Allocation check) Host Password {isPublicHost ? <span className="required">*</span> : ""}</label>
                <input type={"password"} value={terminalFields.hostPassword} placeholder="Enter Host Password" autoComplete="new-password" onChange={(e) => handleTerminalFieldsChange(e, 'hostPassword')} />
              </div>

              <div className="form-field-box switch-box">
                <label className="switch-label complementory">Order Sending To: </label>&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="switch-label complementory">Private Host</label>
                <Switch color="primary" size="medium" onChange={(event) => onHostSwitchChange(event.target.checked)} checked={isPublicHost} />
                <label className="switch-label blue-1">Public Host</label>
              </div>

              {
                isPublicHost ?
                  <div className="public-host">
                    <label>Given username and password will be used for public host authentication.</label>
                  </div>
                  :
                  <div className="private-host">
                    <div className="form-field-box switch-box">
                      <label className="switch-label complementory">Basic Auth</label>
                      <Switch color="primary" size="medium" onChange={(event) => onSwitchChange(event.target.checked)} checked={isAPIKey} />
                      <label className="switch-label blue-1">API Key</label>
                    </div>
                    {isAPIKey && <RadioGroup
                      row
                      value={authType}
                      onChange={handleAuthTypeChange}
                    >
                      <FormControlLabel
                        value="apiKey"
                        control={<Radio />}
                        label="API Key"
                      />
                      <FormControlLabel
                        value="token"
                        control={<Radio />}
                        label="Token"
                      />
                    </RadioGroup>}

                    {
                      isAPIKey && authType === 'apiKey'
                      &&
                      <div className="form-field-box">
                        <label>API Key <span className="required">*</span></label>
                        <input value={terminalFields.apiKey} placeholder="Enter API Key" onChange={(e) => handleTerminalFieldsChange(e, 'apiKey')} />
                      </div>
                    }
                    {!isAPIKey &&
                      <>
                        <div className="form-field-box">
                          <label>Auth URI <span className="required">*</span></label>
                          <input value={terminalFields.authURI} placeholder="Enter Auth URI" onChange={(e) => handleTerminalFieldsChange(e, 'authURI')} />
                        </div>
                        <div className="form-field-box">
                          <label>Username <span className="required">*</span></label>
                          <input value={terminalFields.username} placeholder="Enter Username" autoComplete="new-password" onChange={(e) => handleTerminalFieldsChange(e, 'username')} />
                        </div>
                        <div className="form-field-box">
                          <label>Password <span className="required">*</span></label>
                          <input type={"password"} value={terminalFields.password} placeholder="Enter Password" autoComplete="new-password" onChange={(e) => handleTerminalFieldsChange(e, 'password')} />
                        </div>
                      </>
                    }
                    {isAPIKey && authType === 'token' && (
                      <>
                        <div className="form-field-box">
                          <label>Token <span className="required">*</span></label>
                          <input value={terminalFields.token} placeholder="Enter Token" onChange={(e) => handleTerminalFieldsChange(e, 'token')} />
                        </div>
                        <div className="form-field-box details-block-selection">
                          <input type="checkbox" value={terminalFields.includeUTCDate} checked={terminalFields.includeUTCDate} onChange={(e) => handleTerminalFieldsChange({ target: { value: e.target.checked } }, 'includeUTCDate')} />
                          <label>Include UTC Date</label>
                        </div>
                        <div className="form-field-box params-textfield">
                          <div className="params-head">
                            <div>
                              <label className="label-head">Params</label>
                            </div>
                            <button type="button" onClick={addParam}>+ Add Param</button>
                          </div>


                          {terminalFields.params && terminalFields.params.length > 0 && terminalFields.params.map((param, index) => (
                            <div key={index} className="param-pair">
                              <input
                                value={param.key}
                                placeholder="Key"
                                onChange={(e) => handleParamChange(index, 'key', e.target.value)}
                              />
                              <input
                                value={param.value}
                                placeholder="Value"
                                className="margin-left-8"
                                onChange={(e) => handleParamChange(index, 'value', e.target.value)}
                              />
                              <Tooltip title="Delete Param">
                                <IconButton onClick={() => removeParam(index)} aria-label="delete">
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ))}

                        </div>
                      </>
                    )}
                  </div>
              }

              <div className="form-field-box">
                <label>Order Processing URI <span className="required">*</span></label>
                <input value={terminalFields.orderProcessingURI} placeholder="Enter Order Processing URI" onChange={(e) => handleTerminalFieldsChange(e, 'orderProcessingURI')} />
              </div>
              <div className="form-field-box">
                <label>Order Cancelling URI <span className="required">*</span></label>
                <input value={terminalFields.orderCancellingURI} placeholder="Enter Order Cancelling URI" onChange={(e) => handleTerminalFieldsChange(e, 'orderCancellingURI')} />
              </div>

              <div className="form-field-box details-block-selection">
                <input type="checkbox" value={terminalFields.setProductQuantitiesAsZero} checked={terminalFields.setProductQuantitiesAsZero} onChange={(e) => handleTerminalFieldsChange({ target: { value: e.target.checked } }, 'setProductQuantitiesAsZero')} />
                <label>Set product quantities as zero</label>
              </div>
              <div className="form-field-box details-block-selection">
                <input type="checkbox" value={terminalFields.shortenTrailerId} checked={terminalFields.shortenTrailerId} onChange={(e) => handleTerminalFieldsChange({ target: { value: e.target.checked } }, 'shortenTrailerId')} />
                <label>Shorten the trailer id</label>
              </div>


              <div className="form-field-box switch-box">
                  <label className="complementory switch-label-text">Enable DriverName Validation</label>&nbsp;
                  <Switch color="primary" size="medium" onChange={(e) => handleTerminalFieldsChange({ target: { value: e.target.checked } }, 'enableValidation')} checked={terminalFields.enableValidation} />
              </div>

              <label><b><u>Time Adjust</u></b></label>
              <div className="form-field-box details-block-selection">
                {/* <input type="checkbox" value={terminalFields.shortenTrailerId} checked={terminalFields.shortenTrailerId} onChange={(e) => handleTerminalFieldsChange({ target: { value: e.target.checked } }, 'shortenTrailerId')} /> */}
                <label>Effective date</label>
                <RemoveCircleOutlineIcon />
                <input value={terminalFields.timeAdjust.effectiveDate} placeholder="Enter hours" onChange={(e) => handleTimeAdjustChange(e, 'effectiveDate')} />
              </div>
              <div className="form-field-box details-block-selection">
                {/* <input type="checkbox" value={terminalFields.shortenTrailerId} checked={terminalFields.shortenTrailerId} onChange={(e) => handleTerminalFieldsChange({ target: { value: e.target.checked } }, 'shortenTrailerId')} /> */}
                <label>Expiration date</label>
                <RemoveCircleOutlineIcon />
                <input value={terminalFields.timeAdjust.expirationDate} placeholder="Enter hours" onChange={(e) => handleTimeAdjustChange(e, 'expirationDate')} />
              </div>

              <div className="form-field-box">
                <label>Terminals</label>
                <AutoComplete
                  loadOptions={loadOptions}
                  defaultOptions={terminalOptions.map(item => {
                    return { value: item._id, label: `${item.control_number} - ${item.name}` }
                  })}
                  onChange={handleTerminalSelectionChange}
                  value={selectedTerminals}
                  onInputChange={handleTerminalsInputChange}
                  inputValue={terminalsInputValue}
                />
                {domainTypes.terminal && (!selectedTerminals || !selectedTerminals.length) && <label className="d-error">Atleast one terminal required for terminal domain</label>}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }, [
    domainName, isProxyDomain, validateRegex, edit, carrierAlias, JSON.stringify(selectedTerminals), terminalsInputValue, JSON.stringify(terminalOptions),
    JSON.stringify(selectedActiveTerminals), activeTerminalsInputValue, JSON.stringify(activeTerminalOptions),
    JSON.stringify(proxyOptions), proxyInputValue, JSON.stringify(selectedProxies),
    domainTypes.carrier, domainTypes.terminal,
    isAPIKey, isPublicHost,
    terminalFields.orderProcessingURI, terminalFields.orderCancellingURI, terminalFields.authURI, terminalFields.apiKey, terminalFields.username, terminalFields.password, terminalFields.setProductQuantitiesAsZero, terminalFields.shortenTrailerId,terminalFields.enableValidation, terminalFields.timeAdjust.effectiveDate, terminalFields.timeAdjust.expirationDate, terminalFields.enableAllocationCheck, terminalFields.hostPassword, terminalFields.hostUsername, 
    carrierFields.orderProcessingCallbackURI, carrierFields.authURI, carrierFields.username, carrierFields.password, carrierFields.sendEmailsForXRef, carrierFields.isFTPChecked, carrierFields.publicKey, JSON.stringify(carrierFields.emailsForXRef),
    tempEmailInputValue,
    authType,
    terminalFields.token, terminalFields.includeUTCDate, JSON.stringify(terminalFields.params)
  ])

  const btnDisabled = useMemo(() => {
    if (!domainName.trim()) {
      return true
    } else if (domainName.trim() && validateRegex) {
      return true
    } else if (addEditDomainLoading) {
      return true
    } else if (domainTypes.terminal) {
      if (!terminalFields.orderProcessingURI.trim() || !terminalFields.orderCancellingURI.trim()) {
        return true
      }

      if (!isPublicHost && !isAPIKey && (!terminalFields.authURI.trim() || !terminalFields.username.trim() || !terminalFields.password.trim())) {
        return true
      }

      if (!isPublicHost && isAPIKey && authType === 'apiKey' && !terminalFields.apiKey) {
        return true
      }

      if (!isPublicHost && isAPIKey && authType === 'token' && !terminalFields.token) {
        return true
      }

      if (!selectedTerminals || !selectedTerminals.length) {
        return true
      }
    } else {
      return false
    }
  }, [validateRegex, domainName, addEditDomainLoading, domainTypes.carrier, domainTypes.terminal, isAPIKey, terminalFields.apiKey, terminalFields.orderProcessingURI, terminalFields.orderCancellingURI, terminalFields.authURI, terminalFields.username, terminalFields.password, selectedTerminals, JSON.stringify(selectedTerminals), isPublicHost,authType,terminalFields.token])

  const addEditModalAction = useMemo(() => {
    let btnContent;

    if (addEditDomainLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      if (edit) {
        btnContent = "Update"
      } else {
        btnContent = "Add"
      }
    }

    return (
      <div className="add-domain-btn-div">
        <button className={`btn add-domain-btn ${btnDisabled && 'disabled'}`} disabled={btnDisabled} onClick={addEditDomain}>
          {btnContent}
        </button>
      </div>
    )
  }, [btnDisabled, addEditDomainLoading, edit, addEditDomain])

  const deleteDomainTitle = "Delete Domain";

  const deleteModalContent = "Are you sure you want to delete this domain?"

  const deleteModalAction = useMemo(() => {
    let btnContent;

    if (deleteDomainLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      btnContent = "Delete"
    }

    return (
      <div className="add-domain-btn-div">
        <button className="btn danger" onClick={deleteDomain}>
          {btnContent}
        </button>
      </div>
    )
  }, [deleteDomainLoading, deleteDomain])

  useEffect(() => {
    setPage(1)
  }, [actAsDomainIdMemoised])

  const headCells = [{
    id: 'domain_name',
    accessor: 'domain_name',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Domain",
    width: "30%",
  }, {
    id: 'carrier_details',
    accessor: 'carrier_details',
    align: 'center',
    disablePadding: false,
    sortEnabled: false,
    label: "Carrier Info",
    width: "25%",
  }, {
    id: 'terminal_details',
    accessor: 'terminal_details',
    align: 'center',
    disablePadding: false,
    sortEnabled: false,
    label: "Terminal Info",
    width: "25%",
  }]

  if (user.attributes['custom:role'] === "superadmin" && !actAsDomainIdMemoised) {
    headCells.push({
      id: 'action',
      accessor: 'action',
      align: 'center',
      disablePadding: false,
      sortEnabled: false,
      label: "Action",
      width: "20%",
    })
  }

  return (
    <div className="root">
      <div className="table-layout">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Domains</h3>
          {(user.attributes['custom:role'] === "superadmin" && !actAsDomainIdMemoised) && <button className="btn add-domain-btn" onClick={openModal}>Add Domain</button>}
        </div>
        <div className="domains-table">
          <Table
            data={domains}
            loading={domainsLoading || !loggedInUser?._id}
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={DomainRow}
            rowProps={{ handleEdit, handleDelete }}
            noDataFoundMessage={`No Domains Found`}
          />
        </div>
      </div>
      <AddEditDomain
        open={open}
        maxWidth="lg"
        title={deleteDomainFlag ? deleteDomainTitle : addEditDomainTitle}
        content={deleteDomainFlag ? deleteModalContent : addEditModalContent}
        action={deleteDomainFlag ? deleteModalAction : addEditModalAction}
        paperClassName={deleteDomainFlag ? "" : "add-domain-paper"}
        onClose={closeModal}
      />
    </div>
  )
};

export default ManageDomains;