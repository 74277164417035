import React, { useMemo } from 'react';
import { Table, TableContainer, TableBody } from '@mui/material';
import LoadingRow from "./Rows/LoadingRow";
import EmptyRow from "./Rows/EmptyRow";
import Pagination from './pagination';
import TableHeader from './header';

const CustomizedTable = ({ data, loading, total, page, perPage, setPage, setPerPage, headCells, row, rowProps, noDataFoundMessage, order, orderBy, setOrder, setOrderBy,isOrdersTable }) => {
  const totalPages = Math.ceil(total / perPage);

  const rows = useMemo(() => {
    const Row = row;
    if (loading) {
      return <LoadingRow colSpan={headCells.length + 1} />;
    }

    if (total === 0 || data.length === 0) {
      return <EmptyRow text={noDataFoundMessage} colSpan={headCells.length + 1} />;
    }

    return data.map((item, index) => {
      const start = (page * 10) - (perPage - 1);
      return <Row key={index} index={start + (index)} item={item} {...rowProps} headCells={headCells} />
    });
  }, [loading, total, headCells, data, row]);

  return (
    <div
      className={`d-table ${isOrdersTable ? "orders-table-layout" : ""}`} // Add a conditional class
      data-testid="table"
    >
      <TableContainer style={{ maxHeight: '70vh' }}>
        <Table className="table" stickyHeader>
          <TableHeader 
            headCells={headCells} 
            order={order}
            orderBy={orderBy} 
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            isOrdersTable={isOrdersTable}
          />
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      {
        totalPages ? (
          <Pagination 
            total={total}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        ) : ''
      }
    </div>
  );
};

export default CustomizedTable;
